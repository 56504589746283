// imports
import React from "react"

// images
import profileImage from "../images/leung_profile_pic_full.jpg"


export default function AboutSection({ data, divStyle, children }) {
    const aboutPage = data.about.edges[0].node

    var content = []
    if (aboutPage.html) {
        content.push(<div dangerouslySetInnerHTML={{ __html: aboutPage.html }} />)
    }

    
    return (
        <div>

            <div id="js-personal-info" className="d-flex justify-content-center align-items-center" style={divStyle}>
                <div className="col-md-8 text text-center">
                    <div className="img mb-4" style={{backgroundImage: "url(" + profileImage + ")"}}></div>
                    <div className="desc">
                        <h2 className="subheading">Hi, I'm</h2>
                        <h1 className="mb-4">Steve Leung</h1>
                        <p className="mb-4">{aboutPage.frontmatter.blurb}</p>
                        {content}
                        {children}
                    </div>
                </div>
            </div>

        </div>
    )
}
