// imports
import React from "react"
import { withPrefix } from "gatsby"
import { graphql } from "gatsby"
import useScript from "../hooks/useScript"
import SEO from "../components/SEO"
import Navbar from "../components/Navbar"
import BackgroundDiv from "../components/BackgroundDiv"
import AboutSection from "../components/AboutSection"


export default function Home({ data }) {
    useScript(withPrefix("scripts/jquery.min.js"));
    useScript(withPrefix("scripts/jquery.waypoints.min.js"));
    useScript(withPrefix("scripts/waypoint.js"));
    useScript("https://www.googletagmanager.com/gtag/js?id=UA-159994104-1");
    useScript(withPrefix("scripts/google-analytics.js"));


    return (
        <div>

        <SEO title="About" description={data.site.siteMetadata.description} />
        <div id="colorlib-page">
            <Navbar />

            {/* main page contents */}
            <div id="colorlib-main">
                <BackgroundDiv className="hero-wrap" style={{height:"100vh", position:"fixed"}}>
                    <div className="overlay"></div>
                </BackgroundDiv>
                <div className="hero-wrap">
                    <AboutSection data={data} divStyle={{}}>
                        <ul className="ftco-social mt-3">
                            <li className="ftco-animate"><a href="https://github.com/stevenaleung"><span className="icon-github" aria-label="github icon"></span></a></li>
                            <li className="ftco-animate"><a href="https://www.linkedin.com/in/stevenaleung"><span className="icon-linkedin" aria-label="linkedin icon"></span></a></li>
                        </ul>
                    </AboutSection>
                </div>
            </div>
            {/* end of #colorlib-main */}
        </div>
        {/* end of #colorlib-page */}

        </div>
    )
}


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        about: allMarkdownRemark(
            filter: {
                frontmatter: {
                    contentType: {
                        in: ["about-me"]
                    }
                }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        blurb
                    }
                    fields {
                        slug
                    }
                    html
                }
            }
        }
    }
`    
